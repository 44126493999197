var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mile-progress" },
    [
      _c(
        "div",
        {
          staticClass: "mile-progress-circular",
          style: _vm.stageBg
            ? "background-image: url(" + _vm.stageBg + ");"
            : false,
        },
        [
          _c(
            "div",
            { staticClass: "mile-progress-circular__inner" },
            [
              _c(
                "div",
                { staticClass: "mile-progress-circular__head" },
                [
                  _c("MileStageCurrent", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.stageId,
                        expression: "stageId",
                      },
                    ],
                    attrs: { id: _vm.stageId, label: _vm.stageLabel },
                  }),
                  _vm.stageId ? _c("MileStageListButton") : _vm._e(),
                ],
                1
              ),
              _c(
                "radial-progress-bar",
                _vm._b(
                  { ref: "mileCircular" },
                  "radial-progress-bar",
                  _vm.circularOptions,
                  false
                )
              ),
            ],
            1
          ),
        ]
      ),
      _c("MileTree", {
        attrs: {
          "is-started": _vm.isStarted,
          "is-staging": _vm.isStaging,
          "is-animate": _vm.isAnimateMileUp,
          "init-image-name": _vm.getImageName(_vm.initTreePercentage),
          "image-name": _vm.treeImage,
          "animation-name": _vm.treeAnimation,
          "stage-id": _vm.stageId,
          "stage-up-times": _vm.stageUpTimes,
        },
      }),
      _c(
        "div",
        { staticClass: "mile-progress-nav" },
        [
          !_vm.isStaging && _vm.lastMileValue >= 0 && _vm.counterOptions
            ? [
                _c("dl", { staticClass: "mile-progress-counter" }, [
                  _c("dt", [_vm._v(_vm._s(_vm.WORDS.MILE_PROGRESS.NOW))]),
                  _c("dd", [
                    _c(
                      "strong",
                      { staticClass: "mile-progress-counter__current" },
                      [
                        _vm.isProgressReady
                          ? _c("number", {
                              ref: "mileCounter",
                              attrs: {
                                from: _vm.fromValue,
                                to: _vm.toValue,
                                format: _vm.counterOptions.format,
                                duration: _vm.counterOptions.duration,
                                delay: _vm.counterOptions.delay,
                                easing: _vm.counterOptions.easing,
                                "animation-paused": "",
                              },
                              on: {
                                start: _vm.startedCounter,
                                update: _vm.updatedCounter,
                                complete: _vm.completedCounter,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "mile-progress-counter__slash" },
                      [_vm._v("/")]
                    ),
                    _c("span", { staticClass: "mile-progress-counter__max" }, [
                      _vm._v(_vm._s(_vm.stageMaxValue)),
                    ]),
                    _c("span", { staticClass: "mile-progress-counter__unit" }, [
                      _vm._v(_vm._s(_vm.WORDS.MILE)),
                    ]),
                  ]),
                ]),
                _c("dl", { staticClass: "mile-progress-next" }, [
                  _c("dt", [_vm._v(_vm._s(_vm.WORDS.MILE_PROGRESS.NEXT))]),
                  _c("dd", [
                    _c("span", { staticClass: "mile-progress-next__label" }, [
                      _vm._v(_vm._s(_vm.nextStageLabel)),
                    ]),
                    _c(
                      "span",
                      { staticClass: "mile-progress-next__leftover" },
                      [
                        _vm._v(
                          " あと" +
                            _vm._s(_vm.leftOverValue) +
                            _vm._s(_vm.WORDS.MILE)
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "mile-progress-buttons" }, [
                  _c("div", { staticClass: "c-buttons c-buttons--nowrap" }, [
                    _c(
                      "div",
                      { staticClass: "c-buttons__inner" },
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              href: _vm.$customUrlScheme.page(
                                "standalone",
                                "url=/mypage/mile/achives"
                              ),
                              "style-type": "quaternary",
                              action: "",
                              fluid: "",
                              squared: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "iconPrepend",
                                  fn: function () {
                                    return [
                                      _c("Icon", {
                                        attrs: { name: "mileReward" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2145544033
                            ),
                          },
                          [_vm._v(" " + _vm._s(_vm.WORDS.MILE_REWARD) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "c-buttons__inner" },
                      [
                        _c(
                          "Button",
                          {
                            attrs: {
                              href: _vm.$customUrlScheme.page(
                                "standalone",
                                "url=/mypage/mile/history"
                              ),
                              "style-type": "quaternary",
                              action: "",
                              fluid: "",
                              squared: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "iconPrepend",
                                  fn: function () {
                                    return [
                                      _c("Icon", {
                                        attrs: { name: "mileHistory" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1996510452
                            ),
                          },
                          [_vm._v(" " + _vm._s(_vm.WORDS.MILE_HISTORY) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "mile-progress-help-stage" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "c-textButton c-textButton--sm c-textButton--right c-textButton--white01",
                      attrs: {
                        href: _vm.$customUrlScheme.dialog("mile-tutorial"),
                      },
                    },
                    [
                      _c("Icon", {
                        staticClass:
                          "c-textButton__icon c-textButton__icon--prepend",
                        attrs: { name: "helpCircle" },
                      }),
                      _vm._v(" " + _vm._s(_vm.WORDS.MILE) + "について "),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }