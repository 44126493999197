export const useMileProgress = () => {
  /**
   * マイルのパーセンテージを返す
   * デザインの都合上、135度ずらして75%の長さで表示
   * @param {number} value
   * @returns {number}
   */
  const getCalcProgress = (value) => Math.floor(value * 0.75);

  /**
   * 画像のファイル名を返す
   * @param {string} value
   * @returns {string}
   */
  const getImageName = (value = 0) => {
    if (value < 21) return '01.png';
    else if (value < 41) return '02.png';
    else if (value < 61) return '03.png';
    else if (value < 81) return '04.png';
    else return '05.png';
  };

  /**
   * コーヒー豆の木の画像IDを返す
   * @returns {string}
   */
  const getAnimation = (value = 0, isAnimateMileUp, treeImage) => {
    // 前回表示時からマイル値に変動がなければ該当の静止画のIDを返す
    if (!isAnimateMileUp) {
      return treeImage;
    } else {
      if (value === 0) return '01.png';
      else if (value < 21) return '01-animate.webp';
      else if (value < 41) return '02-animate.webp';
      else if (value < 61) return '03-animate.webp';
      else if (value < 81) return '04-animate.webp';
      else return '05-animate.webp';
    }
  };

  /**
   * 数値のアニメーションのフォーマット指定
   * @param {number}
   * @returns {number}
   */
  const getCounterFormat = (number) => {
    return number.toFixed();
  };

  return {
    getCalcProgress,
    getImageName,
    getAnimation,
    getCounterFormat
  };
};
