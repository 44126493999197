<template>
  <div class="mile-progress">
    <div
      class="mile-progress-circular"
      :style="stageBg ? `background-image: url(${stageBg});` : false">
      <div class="mile-progress-circular__inner">
        <div class="mile-progress-circular__head">
          <MileStageCurrent
            v-show="stageId"
            :id="stageId"
            :label="stageLabel" />
          <MileStageListButton v-if="stageId" />
        </div>
        <radial-progress-bar ref="mileCircular" v-bind="circularOptions" />
      </div>
    </div>

    <MileTree
      :is-started="isStarted"
      :is-staging="isStaging"
      :is-animate="isAnimateMileUp"
      :init-image-name="getImageName(initTreePercentage)"
      :image-name="treeImage"
      :animation-name="treeAnimation"
      :stage-id="stageId"
      :stage-up-times="stageUpTimes" />

    <div class="mile-progress-nav">
      <template v-if="!isStaging && lastMileValue >= 0 && counterOptions">
        <dl class="mile-progress-counter">
          <dt>{{ WORDS.MILE_PROGRESS.NOW }}</dt>
          <dd>
            <strong class="mile-progress-counter__current">
              <number
                v-if="isProgressReady"
                ref="mileCounter"
                :from="fromValue"
                :to="toValue"
                :format="counterOptions.format"
                :duration="counterOptions.duration"
                :delay="counterOptions.delay"
                :easing="counterOptions.easing"
                @start="startedCounter"
                @update="updatedCounter"
                @complete="completedCounter"
                animation-paused />
            </strong>
            <span class="mile-progress-counter__slash">/</span>
            <span class="mile-progress-counter__max">{{ stageMaxValue }}</span>
            <span class="mile-progress-counter__unit">{{ WORDS.MILE }}</span>
          </dd>
        </dl>

        <dl class="mile-progress-next">
          <dt>{{ WORDS.MILE_PROGRESS.NEXT }}</dt>
          <dd>
            <span class="mile-progress-next__label">{{ nextStageLabel }}</span>
            <span class="mile-progress-next__leftover">
              あと{{ leftOverValue }}{{ WORDS.MILE }}</span>
          </dd>
        </dl>

        <div class="mile-progress-buttons">
          <div class="c-buttons c-buttons--nowrap">
            <div class="c-buttons__inner">
              <Button
                :href="
                  $customUrlScheme.page(
                    'standalone',
                    'url=/mypage/mile/achives'
                  )
                "
                style-type="quaternary"
                action
                fluid
                squared>
                <template v-slot:iconPrepend>
                  <Icon name="mileReward" />
                </template>
                {{ WORDS.MILE_REWARD }}
              </Button>
            </div>

            <div class="c-buttons__inner">
              <Button
                :href="
                  $customUrlScheme.page(
                    'standalone',
                    'url=/mypage/mile/history'
                  )
                "
                style-type="quaternary"
                action
                fluid
                squared>
                <template v-slot:iconPrepend>
                  <Icon name="mileHistory" />
                </template>
                {{ WORDS.MILE_HISTORY }}
              </Button>
            </div>
          </div>
        </div>

        <div class="mile-progress-help-stage">
          <a
            :href="$customUrlScheme.dialog('mile-tutorial')"
            class="c-textButton c-textButton--sm c-textButton--right c-textButton--white01">
            <Icon
              name="helpCircle"
              class="c-textButton__icon c-textButton__icon--prepend" />
            {{ WORDS.MILE }}について
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted
} from '@vue/composition-api';
import { SystemDialogMessage, MileStageType } from '@/constants/enums';
import { colors } from '@/composables/useColors';
import { useNativeConnection } from '@/composables/useNativeConnection';
import { useMileProgress } from '@/composables/useMileProgress';

export default defineComponent({
  components: {
    RadialProgressBar: () => import('vue-radial-progress'),
    MileStageCurrent: () => import('@/components/mypage/mile/StageCurrent.vue'),
    MileStageListButton: () =>
      import('@/components/mypage/mile/StageListButton.vue'),

    MileTree: () => import('@/components/mypage/mile/Tree.vue')
  },

  props: {
    customerData: {
      type: Object,
      default: undefined
    }
  },

  setup: (props, context) => {
    const { getMileage } = useNativeConnection();
    const { getCalcProgress, getImageName, getAnimation, getCounterFormat } =
      useMileProgress();

    const lastMileValue = ref(undefined);
    const PROGRESS_DELAY = 600; // アニメーション表示時のための待機時間
    const PROGRESS_BASE_DURATION = 6400; // 全体のアニメーションの基本となるduration
    const TREE_MAX_STEP = 5;
    const TREE_MAX_PERCENTAGE = 82;

    const isStarted = ref(false); // マイル値の上昇の演出中華
    const isStaging = ref(false); // ステージアップの演出中か
    const stageUpTimes = ref(0);
    const mileCircular = ref(undefined);
    const mileCounter = ref(undefined);

    const mileData = ref(undefined);
    const progressFrom = ref(0);
    const progressTo = ref(0);
    const counterValue = ref(0); // カウントアニメーション中の累計マイル値
    const leftOverValue = ref(0); // 次の収穫までのマイル値
    const fromValue = ref(0);
    const toValue = ref(0);
    const isProgressReady = ref(false); // グラフと数値のアニメーションの準備が完了したか
    const sleep = (ms = 100) =>
      new Promise((resolve) => setTimeout(resolve, ms));

    // https://github.com/wyzantinc/vue-radial-progress
    const circularOptions = reactive({
      totalSteps: 100,
      completedSteps: 0,
      diameter: 220,
      innerStrokeColor: 'rgba(255, 255, 255, 0.8)',
      strokeWidth: 12,
      innerStrokeWidth: 12,
      animateSpeed: 0
    });

    // https://github.com/nkoik/vue-animated-number
    const counterOptions = computed(() => {
      if (!mileData.value) return;
      return {
        from: fromValue.value,
        to: toValue.value,

        format: getCounterFormat,
        duration: progressDuration.value * 0.001,
        delay: PROGRESS_DELAY * 0.001,
        easing: 'Power1.ease'
      };
    });

    const isAnimateMileUp = computed(() => {
      if (!mileNowValue.value || isNaN(lastMileValue.value)) return;
      return lastMileValue.value < mileNowValue.value;
    });

    const isCurrentStage = computed(() =>
      isAnimateMileUp.value ? stageUpTimes.value === stageLength.value : true
    );

    // 最新のマイル値
    const mileNowValue = computed(() => {
      if (!mileData.value) return;
      return mileData.value?.['now_mileage'] || 0;
    });

    const stageLength = computed(() => {
      if (!mileData.value) return;
      return mileData.value?.rank?.length - 1 || 0;
    });

    const stageId = computed(() => {
      if (!mileData.value) return;
      return MileStageType.getMileStageTypeKeyById(
        mileData.value?.rank[stageUpTimes.value]?.['id']
      );
    });

    const stageLabel = computed(() => {
      if (!mileData.value) return;
      return mileData.value?.rank[stageUpTimes.value]?.['name'] || '';
    });

    // ステージ完了時の最大マイル値
    const stageMaxValue = computed(() => {
      if (!mileData.value) return;
      return mileData.value?.rank[stageUpTimes.value]?.['threshold'] || 0;
    });

    // ステージ開始時の最小マイル値
    const stageMinValue = computed(() => {
      if (!mileData.value) return;
      return stageUpTimes.value > 0
        ? mileData.value?.rank[stageUpTimes.value - 1]?.['threshold']
        : 0;
    });

    // ステージ単体における次のステージアップまでに必要なマイル値
    const stageBg = computed(() => stageImages.value?.['background']);

    const nextStageId = computed(() =>
      MileStageType.getMileStageTypeKeyById(stageId.value + 1)
    );
    const nextStageLabel = computed(() =>
      MileStageType.getTypeName(nextStageId.value, 'ja')
    );

    const progressDuration = computed(
      () =>
        (PROGRESS_BASE_DURATION * treeCurrentStep.value) / TREE_MAX_STEP || 0
    );

    /**
     * コーヒー豆の木のパーセンテージの初期表示を算出
     * @returns {number}
     */
    const initTreePercentage = computed(() => {
      if (!mileData.value) return;
      const value = progressFrom.value * TREE_MAX_PERCENTAGE * 0.01;
      return value || 0;
    });

    /**
     * コーヒー豆の木のパーセンテージを算出
     * @returns {number}
     */
    const treePercentage = computed(() => {
      if (!mileData.value) return;
      const currentStagePercentage =
        (counterValue.value / stageMaxValue.value) * TREE_MAX_PERCENTAGE;
      // eslint-disable-next-line no-console
      console.log('currentStagePercentage :>> ', currentStagePercentage);

      if (stageUpTimes.value === 0) {
        return currentStagePercentage <= initTreePercentage.value
          ? initTreePercentage.value
          : currentStagePercentage;
      } else {
        return isCurrentStage.value
          ? currentStagePercentage > progressTo.value
            ? TREE_MAX_PERCENTAGE * progressTo.value * 0.01
            : currentStagePercentage
          : currentStagePercentage;
      }
    });

    /**
     * コーヒー豆の木の画像IDを算出
     * @returns {string}
     */
    const treeImage = computed(() => {
      if (!treePercentage.value) return;
      const value = Math.floor(treePercentage.value);
      return getImageName(value);
    });

    /**
     * コーヒー豆の木の画像IDを算出
     * @returns {string}
     */
    const treeAnimation = computed(() => {
      if (!treePercentage.value) return;
      const value = Math.floor(treePercentage.value);
      return getAnimation(value, isAnimateMileUp.value, treeImage.value);
    });

    /**
     * コーヒー豆の木の段階数を算出
     * @returns {number}
     */
    const treeCurrentStep = computed(() => {
      if (!mileData.value) return;
      const value = treePercentage.value;
      if (value < 21) return 1;
      else if (value < 41) return 2;
      else if (value < 61) return 3;
      else if (value < 81) return 4;
      else return 5;
    });

    const stageImages = computed(() => {
      if (!mileData.value) return;
      return mileData.value?.rank[stageUpTimes.value]?.['image_urls'];
    });

    // eslint-disable-next-line no-console
    const completedSteps = computed(() => console.debug('updated step'));

    /**
     * グラフのアニメーションを開始
     */
    const playCircular = async () => {
      await sleep();
      circularOptions.completedSteps = getCalcProgress(progressTo.value);
      circularOptions.animateSpeed = progressDuration.value;
    };

    /**
     * アニメーションをリセット
     */
    const reset = async () => {
      await resetCounter();
      await resetCircular();
    };

    /**
     * グラフのアニメーションをリセット
     */
    const resetCircular = () => {
      circularOptions.completedSteps = 0;
      circularOptions.animateSpeed = 0;
    };

    /**
     * グラフのアニメーションをリセット
     */
    const resetCounter = () => {
      fromValue.value =
        stageUpTimes.value === 0 ? lastMileValue.value : stageMinValue.value;
      toValue.value =
        // 現在のステージ
        isCurrentStage.value
          ? mileNowValue.value
          : // 前回表示時から現在までの過程のステージ
          mileNowValue.value >= stageMaxValue.value
            ? stageMaxValue.value
            : mileNowValue.value;
    };

    /**
     * 数値のアニメーションを開始
     */
    const playCounter = async () => {
      if (!mileCounter.value) return;
      if (stageUpTimes.value > 0) {
        circularOptions.completedSteps = 0;
      }
      mileCounter.value.play();
    };

    /**
     * 数値のアニメーションを停止
     */
    const pauseCounter = () => {
      mileCounter.value.pause();
    };

    /**
     * 数値のアニメーション開始時の処理
     */
    const startedCounter = () => {
      if (!mileCounter.value) return;
      // eslint-disable-next-line no-console
      console.debug('counter started');
      isStarted.value = true;
    };

    /**
     * 数値のアニメーション更新時の処理
     */
    const updatedCounter = async () => {
      counterValue.value = Number(mileCounter.value?.$el.innerText) || 0;
    };

    /**
     * 数値とグラフのアニメーション処理
     */
    const playProgress = async () => {
      playCircular();
      playCounter();
    };

    /**
     * 数値のアニメーション終了時の処理
     */
    const completedCounter = async () => {
      // eslint-disable-next-line no-console
      console.debug('counter done');
      if (stageUpTimes.value < stageLength.value) {
        await pauseCounter();
        await sleep(1200);
        context.emit('switch-staging', true);
        isStaging.value = true;
      }
    };

    /**
     * アニメーションのリスタート
     */
    const restartCounter = async () => {
      await stageUpTimes.value++;
      reset().finally(async () => {
        await sleep();
        isStaging.value = false;
        context.emit('fetched-images', stageImages.value);
        animateProgress();
      });
    };

    /**
     * ユーザのマイル情報を取得
     */
    const fetchMileData = async () => {
      try {
        context.root.$_loading_start();

        let params = new URLSearchParams();
        params.append('mileage', lastMileValue.value);
        const { data } = await context.root
          .$repositories('mileage')
          .getProgressRank(params);
        mileData.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.emit('fetched-images', stageImages.value);
        context.root.$_loading_stop();
      }
    };

    /**
     * マイルの数値・グラフのアニメーション処理
     */
    const animateProgress = async () => {
      progressFrom.value =
        mileData.value?.rank[stageUpTimes.value]?.['start_progress'] || 0;
      progressTo.value =
        mileData.value?.rank[stageUpTimes.value]?.['end_progress'] || 0;

      // あと○○マイル
      leftOverValue.value =
        props.customerData?.['mileage_detail']?.['next_goal'] || 0;

      circularOptions.completedSteps = await getCalcProgress(
        progressFrom.value
      );
      counterValue.value = Number(mileCounter.value?.$el.innerText) || 0;

      if (!isAnimateMileUp.value) return;

      if (stageUpTimes.value === 0) {
        // 初期表示時のアニメーションを読み込むため待機
        await sleep(PROGRESS_DELAY);
        playProgress();
      } else {
        await sleep(300);
        playProgress();
      }
    };

    circularOptions.startColor = colors.common.green01;
    circularOptions.stopColor = colors.common.green01;

    onMounted(async () => {
      const { mileage_last } = await getMileage();
      lastMileValue.value = await mileage_last;
      // eslint-disable-next-line no-console
      console.debug('lastMileValue.value :>> ', lastMileValue.value);
      await fetchMileData();
      reset().finally(() => {
        isProgressReady.value = true;
        animateProgress();
      });
    });

    return {
      WORDS,
      lastMileValue,
      mileData,
      mileCircular,
      mileCounter,
      stageBg,
      stageId,
      stageLabel,
      stageMinValue,
      stageMaxValue,
      nextStageLabel,
      leftOverValue,
      circularOptions,
      counterOptions,
      mileNowValue,
      fromValue,
      toValue,
      completedSteps,
      isProgressReady,

      isStarted,
      isStaging,
      isAnimateMileUp,
      stageLength,
      progressDuration,
      initTreePercentage,
      treeImage,
      treeAnimation,
      treeCurrentStep,
      treePercentage,
      stageUpTimes,

      getImageName,
      startedCounter,
      updatedCounter,
      completedCounter,
      restartCounter
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';
.mile-progress {
  position: relative;
  .radial-progress-container {
    transform: rotate(-135deg);
  }
}

.mile-progress-circular {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &__inner {
    z-index: 0;
    position: relative;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: calc(env(safe-area-inset-top));
    background-image: linear-gradient(
      180deg,
      rgba(20, 95, 137, 0.22) -15%,
      rgba(20, 95, 137, 0.022) 100%
    );
  }
  &__head {
    z-index: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 6.5em;
    align-items: center;
    justify-content: space-between;
    padding: 5.6rem 1.6rem 1.2rem;
    width: 100%;
    color: #fff;
    font-weight: bold;
    filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.3));
  }
}

.mile-progress-nav {
  z-index: 1;
  position: relative;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -44px;
  padding: 28px 16px 12px;
  background-color: variables.$secondary01;
  width: 100%;
  box-shadow: 0 6px 8px rgba($color: variables.$black01, $alpha: 0.05),
    0 4px 6px rgba($color: variables.$black01, $alpha: 0.1);
  dl {
    display: grid;
    align-items: end;
    grid-template-columns: 7em 1fr;
    margin-bottom: 12px;
    width: 100%;
    color: variables.$white02;
    font-size: 13px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    &:not(:last-of-type) {
      padding-bottom: 11px;
      border-bottom: 1px solid rgba($color: variables.$white02, $alpha: 0.5);
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    > dt {
      padding-right: 12px;
      padding-bottom: 4px;
      font-size: 13px;
    }
    > dd {
      display: grid;
      align-items: end;
      text-align: right;
    }
  }
}

.mile-progress-counter {
  &__slash,
  &__max,
  &__unit {
    padding-left: 4px;
    font-size: 16px;
  }
  &__slash,
  &__max {
    padding-bottom: 0.55em;
  }
  &__unit {
    padding-bottom: 0.5em;
  }
  &__current {
    padding-right: 2px;
    font-size: 50px;
    letter-spacing: 1px;
  }
  > dd {
    grid-template-columns: auto auto auto 4em;
    justify-content: end;
  }
}

.mile-progress-next {
  &__label,
  &__leftover {
    padding-bottom: 0.3em;
    padding-left: 4px;
  }
  &__label {
    text-align: left;
  }
  > dd {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.mile-progress-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .c-buttons__inner {
    width: 50%;
  }
  @include mixin.mq {
    .c-button {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

.mile-progress-help-stage {
  width: 100%;
}
</style>
